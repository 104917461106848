@mixin mq($breakpoint: medium) {
	@if $breakpoint == xsmall {
		@media screen and (min-width: 320px) {
			@content;
		}
	} @else if $breakpoint == small {
		@media screen and (min-width: 600px) {
			@content;
		}
	} @else if $breakpoint == medium {
		@media screen and (max-width: 768px) {
			@content;
		}
	} @else if $breakpoint == large {
		@media screen and (max-width: 1000px) {
			@content;
		}
	} @else if $breakpoint == xlarge {
		@media screen and (min-width: 1280px) {
			@content;
		}
	}
}

@mixin fs($size) {
    font-size: $size + px;
    font-size: ($size / 10) + rem;
}
