html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
}

*, :before, :after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

hr {
  overflow: visible;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden], template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

ul li {
  list-style: none;
}

code, kbd, pre, samp {
  font-family: monospace;
}

b, strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

input {
  border-radius: 0;
}

button, [type="button"], [type="reset"], [type="submit"], [role="button"] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

[type="number"] {
  width: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button {
  overflow: visible;
}

button::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="reset"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

button, input, select, textarea {
  color: inherit;
  background-color: #0000;
  border-style: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  white-space: normal;
  border: 0;
  max-width: 100%;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

audio, canvas, progress, video {
  display: inline-block;
}

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

::selection {
  color: #000;
  text-shadow: none;
  background-color: #b3d4fc;
}

a {
  vertical-align: baseline;
  color: #3b3e3d;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
}

a:hover {
  transition: all .8s;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #3b3e3d;
  letter-spacing: 1.5px;
  background-color: #fbfdfc;
  width: 100%;
  height: 100%;
  font-family: Hiragino Sans, sans-serif;
  font-size: 1.4rem;
  line-height: 1.7;
}

input, textarea, button {
  color: #3b3e3d;
  border: 1px solid #3b3e3d;
  outline: 0;
  font-size: 16px;
}

a:focus {
  outline: 0;
}

.clearfix:after {
  content: " ";
  clear: both;
  display: block;
}

.fs-xs {
  font-size: 10px;
}

@media screen and (width <= 768px) {
  .xxx-large, .xx-large, .x-large {
    font-size: 1.8rem;
  }
}

.fw-lighter {
  font-weight: 200;
}

.fw-light {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: 800;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-just {
  text-align: justify;
}

.only-sp {
  display: none !important;
}

@media screen and (width <= 768px) {
  .only-sp {
    display: block !important;
  }

  .only-pc {
    display: none !important;
  }
}

.pd10 {
  padding: 10px !important;
}

@media screen and (width <= 768px) {
  .pd10 {
    padding: 10px !important;
  }
}

.pd20 {
  padding: 20px !important;
}

@media screen and (width <= 768px) {
  .pd20 {
    padding: 10px !important;
  }
}

.pd30 {
  padding: 30px !important;
}

@media screen and (width <= 768px) {
  .pd30 {
    padding: 10px !important;
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb05 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container--m {
  max-width: 860px;
  margin: 0 auto;
}

.container--s {
  max-width: 600px;
  margin: 0 auto;
}

.container--xs {
  max-width: 320px;
  margin: 0 auto;
}

.bg-main {
  background-color: #00b27a;
}

.bg-gray {
  background-color: #f6f6f6;
}

.bg-dark-gray {
  background-color: #3b3e3d;
}

.bg-white {
  background-color: #fffcfa;
}

.bg-navy {
  background-color: #012b51;
}

.fc-base100 {
  color: #fbfdfc !important;
}

.fc-base200 {
  color: #f6f6f6 !important;
}

.fc-base300 {
  color: #3b3e3d !important;
}

.fc-base400 {
  color: #000 !important;
}

.fc-main {
  color: #00b27a !important;
}

.fc-accent {
  color: #e91e79;
}

.fc-pink-gradation {
  background: linear-gradient(90deg, #fa9874 0%, #f17dc0 35%, #799ded 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.fc-red {
  color: #ec0000;
}

.fc-navy-gradation {
  background: linear-gradient(90deg, #012b51 0%, #033f6b 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.flex--between {
  justify-content: space-between;
}

.flex--around {
  justify-content: space-around;
}

.flex--end {
  justify-content: flex-end;
}

.flex--center {
  justify-content: center;
}

.flex--aligncenter {
  align-items: center;
}

.flex--alignend {
  align-items: flex-end;
}

.flex--direction {
  flex-direction: column;
}

.flex--reverse {
  flex-direction: row-reverse;
}

.flex {
  flex-wrap: wrap;
  display: flex;
}

.flex .flex__item--2 {
  width: calc(50% - 10px);
  margin: 5px;
}

.flex .flex__item--3 {
  width: calc(33.3333% - 5px);
  margin: 2.5px;
}

.flex .flex__item--4 {
  width: calc(25% - 5px);
  margin: 2.5px;
}

.flex .flex__item--5 {
  width: calc(20% - 5px);
  margin: 2.5px;
}

@media screen and (width <= 768px) {
  .flex--sp100 > div, .flex--sp100 > article {
    margin: 5px;
    width: calc(100% - 10px) !important;
  }

  .flex--sp100 > li {
    margin: 5px;
    width: calc(1000% - 10px) !important;
  }

  .flex--sp50 > div, .flex--sp50 > article, .flex--sp50 > li {
    margin: 5px;
    width: calc(50% - 10px) !important;
  }
}

.iframeWrap {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.iframeWrap iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

.table tr {
  border-bottom: 1px solid #bea38e;
}

.table th {
  vertical-align: top;
  width: 20%;
}

@media screen and (width <= 768px) {
  .table th {
    width: 35%;
  }
}

.table th, .table td {
  text-align: left;
  padding: 20px;
}

@media screen and (width <= 768px) {
  .table th, .table td {
    vertical-align: middle;
    padding: 0 15px;
    font-size: 12px;
  }
}

.table td {
  width: 80%;
  padding: 20px;
}

.table--width {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.table--width th:first-child {
  border-radius: 5px 0 0;
}

.table--width th:last-child {
  border-right: 1px solid #3b3e3d;
  border-radius: 0 5px 0 0;
}

.table--width th {
  text-align: center;
  color: #fff;
  background: #3b3e3d;
  border-top: 1px solid #3b3e3d;
  border-bottom: 1px solid #3b3e3d;
  border-left: 1px solid #3b3e3d;
  width: 25%;
  padding: 10px 0;
}

.table--width td {
  text-align: center;
  border-top: none;
  border-bottom: 1px solid #3b3e3d;
  border-left: 1px solid #3b3e3d;
  width: 25%;
  padding: 10px 0;
}

.table--width td:last-child {
  border-right: 1px solid #3b3e3d;
}

.table--width tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

.table--width tr:last-child td:last-child {
  border-radius: 0 0 5px;
}

.btn img {
  vertical-align: middle;
  width: 20px;
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}

.btn a, .btn input {
  color: #fffcfa;
  background-color: #00b27a;
  border: 2px solid #00b27a;
  display: block;
}

.btn a:hover, .btn input:hover {
  color: #00b27a;
  background-color: #fffcfa;
  border: 2px solid #00b27a;
}

.btn--default a {
  text-align: center;
  max-width: 240px;
  padding: 10px 0;
  display: block;
}

.btn--reverse a, .btn--reverse input {
  color: #00b27a;
  background-color: #fffcfa;
  border: 2px solid #fffcfa;
}

.btn--reverse a:hover, .btn--reverse input:hover {
  color: #fffcfa;
  background-color: #00b27a;
  border: 2px solid #fffcfa;
}

.tab__item {
  background-color: #3b3e3d;
  border: 1px solid #fbfdfc;
  width: 25%;
}

@media screen and (width <= 768px) {
  .tab__item {
    width: 50%;
  }
}

.tab__item a {
  color: #fbfdfc;
  text-align: center;
  padding: 5px 20px;
  display: block;
}

.tab__content {
  padding-top: 20px;
  display: none;
}

.tab .is_active {
  background-color: #000;
}

.tab .is_show {
  display: block;
}

.tag__item {
  color: #bdbdbd;
  margin: 2.5px;
}

.tag__item:before {
  content: "#";
}

.form {
  text-align: left;
  margin-left: 20%;
  font-size: 14px;
}

@media screen and (width <= 768px) {
  .form {
    margin-left: 0%;
    padding: 20px;
  }
}

.form__head {
  color: #fbfdfc;
}

.form__item {
  margin-bottom: 20px;
}

.form__item--txt textarea {
  text-indent: 1em;
  width: 100%;
  padding: 10px 0;
}

.form__item input, .form__item button, .form__item textarea {
  text-indent: 1em;
  color: #000;
  background-color: #fffcfa;
  border: 0 solid #f6f6f6;
  width: 100%;
}

.form__item input {
  letter-spacing: 2px;
  border: 0;
  width: 100%;
  padding: 12px 0;
  text-decoration: none;
  display: block;
}

.form__item--contact input {
  color: #fffcfa;
  cursor: pointer;
  background-color: #bea38e;
}

.form__item--contact input:hover {
  opacity: .8;
}

.header-nav.active {
  transform: translateY(0%);
}

.toggle {
  display: none;
}

@media screen and (width <= 768px) {
  .toggle {
    cursor: pointer;
    z-index: 3;
    width: 42px;
    height: 42px;
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
  }
}

.toggle span {
  border-bottom: 2px solid #000;
  transition: all .35s ease-in-out;
  display: block;
  position: absolute;
  left: 6px;
}

.toggle span:first-child {
  width: 35px;
  top: 7px;
}

.toggle span:nth-child(2) {
  width: 35px;
  top: 17px;
}

.toggle span:nth-child(3) {
  width: 35px;
  top: 27px;
}

.toggle.active span {
  border-bottom: 2px solid #000;
  box-shadow: 0 0 #000;
}

.toggle.active span:first-child {
  width: 35px;
  top: 18px;
  left: 6px;
  transform: rotate(-45deg);
}

.toggle.active span:nth-child(2), .toggle.active span:nth-child(3) {
  width: 35px;
  top: 18px;
  transform: rotate(45deg);
}

.card {
  margin-bottom: 20px;
}

.card__item {
  width: 49%;
  padding: 20px;
  box-shadow: 0 7px 20px -6px #0000004d;
}

.card__item p {
  padding: 20px 10px;
  font-size: 2.4rem;
  font-weight: 700;
}

.card__item p:first-child {
  border-bottom: 2px solid #3b3e3d1a;
}

@media screen and (width <= 768px) {
  .card {
    margin-bottom: 10px;
  }

  .card__item {
    width: 100%;
    padding: 10px;
  }

  .card__item p {
    padding: 20px 0;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .card__item p:first-child {
    border-bottom: 2px solid #3b3e3d1a;
  }
}

.cs {
  padding: 40px;
}

@media screen and (width <= 768px) {
  .cs {
    padding: 20px;
  }
}

.cs__head {
  text-align: center;
  margin-bottom: 10px;
}

.cs__btn {
  text-align: center;
  margin-bottom: 20px;
}

.cs__item {
  border-radius: 40px;
  margin-bottom: 20px;
  position: relative;
}

@media screen and (width <= 768px) {
  .cs__item {
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }
}

.cs__item--col2 {
  width: 49%;
}

@media screen and (width <= 768px) {
  .cs__item--col2 {
    width: 100%;
  }
}

.cs__item--col3 {
  width: 32%;
}

@media screen and (width <= 768px) {
  .cs__item--col3 {
    width: 100%;
  }
}

.cs__innr {
  padding: 40px 40px 0;
}

@media screen and (width <= 768px) {
  .cs__innr {
    padding: 20px 20px 0;
  }
}

.cs__name {
  text-align: center;
  margin-bottom: 10px;
  font-size: 2.4rem;
}

@media screen and (width <= 768px) {
  .cs__name {
    font-size: 1.8rem;
  }
}

.cs__label {
  color: #00b27a;
  text-align: center;
  border: 1px solid #00b27a;
  border-radius: 20px;
  max-width: 200px;
  margin: 0 auto 20px;
  padding: 5px 10px;
  font-size: 1.2rem;
  display: block;
}

.cs__txt {
  margin-bottom: 20px;
}

.cs img {
  width: 100%;
}

.cs__catch {
  text-align: center;
  margin: 0 auto 20px;
  font-size: 3.6rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .cs__catch {
    font-size: 2.4rem;
  }
}

.header {
  background-color: #fbfdfc;
  padding: 40px;
  font-size: 1.6rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .header {
    padding: 20px;
  }
}

.header__top {
  margin-bottom: 20px;
}

@media screen and (width <= 768px) {
  .header__top {
    background-color: #fbfdfc;
  }
}

.header__logo img {
  vertical-align: middle;
  width: 217px;
}

@media screen and (width <= 768px) {
  .header__logo {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 10px;
  }

  .header__logo img {
    width: 200px;
  }

  .header__list {
    z-index: 2;
    text-align: center;
    background-color: #3b3e3d;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.header__item {
  margin-left: 20px;
}

.header__item img {
  width: 20px;
}

@media screen and (width <= 768px) {
  .header__item {
    margin-left: 10px;
  }

  .header__item:nth-child(3) img, .header__item:nth-child(2) img {
    filter: invert();
  }
}

.header-contact {
  text-align: center;
  max-width: 200px;
  font-size: 1.2rem;
}

.header-contact a {
  padding: 5px 10px;
}

@media screen and (width <= 768px) {
  .header-nav {
    z-index: 2;
    text-align: center;
    background: #fbfdfc;
    border-top: 4px solid #00b27a;
    border-bottom: 4px solid #00b27a;
    width: 100%;
    margin-right: 0;
    transition: all .6s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  .header-nav__list {
    width: 100%;
    padding: 20px 0 0;
    display: block;
  }
}

.header-nav__item {
  border-right: 1px solid #0000001a;
  padding: 0 20px;
}

@media screen and (width <= 768px) {
  .header-nav__item {
    border-bottom: 1px solid #0000001a;
    border-right: 0 solid #0000001a;
    margin: 10px 0;
    padding-bottom: 10px;
  }
}

.header-nav__item:last-child {
  border-right: 0 solid #0000001a;
}

@media screen and (width <= 768px) {
  .header-nav__item:last-child {
    margin: 0;
  }

  .hero {
    display: block;
  }
}

.hero__left {
  width: 40%;
  position: relative;
}

@media screen and (width <= 768px) {
  .hero__left {
    width: 100%;
    display: block;
  }
}

.hero__wrap {
  margin-right: -50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width <= 768px) {
  .hero__wrap {
    margin-right: 0%;
    padding: 20px;
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%);
  }
}

.hero__catch {
  margin-bottom: 20px;
  font-size: 3.6rem;
}

@media screen and (width <= 768px) {
  .hero__catch {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.hero__head {
  margin-bottom: 10px;
}

.hero__txt {
  margin-bottom: 20px;
}

.hero__right {
  width: 60%;
}

@media screen and (width <= 768px) {
  .hero__right {
    width: 100%;
    display: block;
  }

  .hero__btn a {
    margin: 0 auto;
  }
}

.top-menu {
  width: 100%;
  padding: 40px;
}

@media screen and (width <= 768px) {
  .top-menu {
    padding: 20px;
  }
}

a:hover .top-menu__btn {
  letter-spacing: 3px;
  transition: all 1s;
}

.top-menu__item {
  width: 33%;
  position: relative;
}

@media screen and (width <= 768px) {
  .top-menu__item {
    width: 100%;
  }
}

.top-menu__txt {
  text-align: center;
  margin-right: -50%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-menu__head {
  margin-bottom: 10px;
  font-size: 2.4rem;
}

.top-news {
  margin: 0 40px;
  padding: 40px;
}

@media screen and (width <= 768px) {
  .top-news {
    margin: 0 20px;
    padding: 20px;
  }
}

.top-news__head {
  margin-bottom: 10px;
}

.top-news__btn {
  border-bottom: 1px solid #0000001a;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.top-news__item {
  margin-bottom: 10px;
}

.top-news__date {
  margin-right: 10px;
}

.top-manga {
  margin: 0 40px;
  padding: 40px 40px 0;
}

@media screen and (width <= 768px) {
  .top-manga {
    margin: 0 20px;
    padding: 20px;
  }
}

.top-manga__left {
  width: 50%;
  position: relative;
}

@media screen and (width <= 768px) {
  .top-manga__left {
    width: 100%;
  }
}

.top-manga__right {
  width: 50%;
}

@media screen and (width <= 768px) {
  .top-manga__right {
    width: 100%;
  }
}

.top-manga__innr {
  text-align: center;
  width: 50%;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width <= 768px) {
  .top-manga__innr {
    width: 55%;
    margin-right: 0%;
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%);
  }
}

.top-manga__head {
  max-width: 400px;
}

@media screen and (width <= 768px) {
  .top-manga__head {
    text-align: center;
    width: 180%;
    margin: 0 auto 10px;
    display: block;
  }

  .top-manga__btn {
    text-align: center;
    width: 180%;
    margin: 0 auto 10px;
  }
}

.top-manga__img {
  max-width: 400px;
}

.footer {
  padding: 40px;
}

@media screen and (width <= 768px) {
  .footer {
    padding: 20px 20px 80px;
  }
}

.footer__top {
  margin-bottom: 20px;
}

.footer__item {
  margin-left: 20px;
}

.footer__item img {
  width: 20px;
}

@media screen and (width <= 768px) {
  .footer__item:first-child {
    text-align: center;
    width: 100%;
    margin: 0 auto 20px;
  }
}

.footer-logo img {
  width: 217px;
}

.footer-nav__item {
  padding: 0 20px;
}

@media screen and (width <= 768px) {
  .footer-nav__item {
    text-align: left;
    padding: 0 10px;
    font-size: 1.2rem;
  }
}

.about {
  text-align: center;
  padding-bottom: 40px;
}

.about__innr {
  padding: 0 20px 20px;
}

.about__head {
  font-size: 4.8rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .about__head {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.about__txt {
  color: #3b3e3d;
}

.about__copy {
  font-size: 48px;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .about__copy {
    font-size: 24px;
  }
}

.product {
  padding-bottom: 40px;
}

.product__head {
  font-size: 4.8rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .product__head {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.product__price {
  margin-bottom: 10px;
}

.product__price span:first-child {
  font-size: 4.8rem;
}

.product__price span:nth-child(2) {
  font-size: 1.2rem;
}

.product__txt {
  margin-bottom: 20px;
}

.product__top {
  padding: 40px;
}

@media screen and (width <= 768px) {
  .product__top {
    padding: 80px 20px 0;
  }
}

.product__top .product__innr {
  width: 60%;
}

@media screen and (width <= 768px) {
  .product__top .product__innr {
    width: 100%;
  }
}

.product__top .product__logo {
  max-width: 300px;
  margin-bottom: 10px;
}

.product__top .product__img {
  width: 40%;
  padding: 80px 0 0;
}

.product__top .product__img img {
  width: 80%;
}

@media screen and (width <= 768px) {
  .product__top .product__img {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 0;
  }

  .product__top .product__img img {
    width: 100%;
  }

  .product__top .product__price {
    text-align: center;
  }
}

.product__top .btn a {
  margin: 0 0 40px;
}

@media screen and (width <= 768px) {
  .product__top .btn a {
    text-align: center;
    margin: 0 auto 20px;
  }
}

.product__bottom {
  padding: 40px;
}

@media screen and (width <= 768px) {
  .product__bottom {
    padding: 0;
  }
}

.product__bottom > div {
  width: 50%;
  padding: 80px 20px 0;
}

@media screen and (width <= 768px) {
  .product__bottom > div {
    width: 100%;
  }
}

.product__bottom .product__logo {
  max-width: 240px;
  margin: 0 auto 40px;
}

.product__bottom .product__head {
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.2;
}

.product__bottom .product__txt {
  padding: 0 20px;
}

@media screen and (width <= 768px) {
  .product__bottom .product__txt {
    padding: 0;
  }
}

.product__bottom .product__price {
  text-align: center;
}

.product__bottom .btn a {
  text-align: center;
  margin: 0 auto 40px;
}

.product__bottom .product__img {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  padding: 0;
}

.nobiruno__head {
  font-size: 4.8rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .nobiruno__head {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.nobiruno__challenge {
  padding: 40px;
}

@media screen and (width <= 768px) {
  .nobiruno__challenge {
    padding: 20px;
  }
}

.nobiruno .nobiruno-hero {
  text-align: center;
  padding: 40px;
}

.nobiruno .nobiruno-hero__img-package {
  max-width: 25%;
  margin: 0 auto 20px;
}

.nobiruno .nobiruno-hero__logo {
  max-width: 10%;
  margin: 0 auto 20px;
}

.nobiruno .nobiruno-hero__img-shadow {
  max-width: 25%;
  margin: 0 auto 20px;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-hero {
    padding: 20px;
  }

  .nobiruno .nobiruno-hero__img-package, .nobiruno .nobiruno-hero__logo, .nobiruno .nobiruno-hero__img-shadow {
    max-width: 50%;
    margin: 0 auto 10px;
  }

  .nobiruno .nobiruno-hero__txt {
    text-align: left;
  }
}

.nobiruno .nobiruno-about {
  padding: 40px 0 40px 40px;
}

.nobiruno .nobiruno-about__left, .nobiruno .nobiruno-about__right {
  width: 50%;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-about {
    padding: 20px;
  }

  .nobiruno .nobiruno-about__left, .nobiruno .nobiruno-about__right {
    width: 100%;
  }
}

.nobiruno .nobiruno-detail {
  text-align: center;
  padding: 40px;
}

.nobiruno .nobiruno-detail__img-arginine {
  max-width: 50%;
  margin: 0 auto 20px;
}

.nobiruno .nobiruno-detail__txt {
  margin: 0 auto 20px;
}

.nobiruno .nobiruno-detail__img-detail {
  margin: 0 auto 80px;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-detail {
    padding: 20px;
  }

  .nobiruno .nobiruno-detail__img-arginine {
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .nobiruno .nobiruno-detail__txt {
    text-align: left;
    margin: 0 auto 20px;
    padding: 0 10px;
  }

  .nobiruno .nobiruno-detail__img-detail {
    margin: 0 auto 20px;
  }
}

.nobiruno .nobiruno-howto {
  padding: 40px;
}

.nobiruno .nobiruno-howto .nobiruno__head {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-howto {
    padding: 20px;
  }
}

.nobiruno .nobiruno-howto__top {
  text-align: center;
  margin-bottom: 40px;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__txt {
  margin-right: -50%;
  font-size: 2.4rem;
  font-weight: 700;
  position: absolute;
  transform: translate(-50%, -50%);
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__left {
  width: 49%;
  position: relative;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__left div {
  height: 100px;
  display: block;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__left img {
  height: 500px;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__left .nobiruno-howto__txt {
  top: 15%;
  left: 50%;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__right {
  width: 49%;
  position: relative;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__right div {
  height: 100px;
  display: block;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__right img {
  width: 100%;
  height: 500px;
}

.nobiruno .nobiruno-howto__top .nobiruno-howto__right .nobiruno-howto__txt {
  color: #fbfdfc;
  filter: brightness(130%);
  top: 58%;
  left: 50%;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-howto__top {
    margin-bottom: 20px;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__txt {
    font-size: 1.8rem;
    top: 50%;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__left {
    width: 100%;
    margin-bottom: 20px;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__left div {
    height: 0;
    display: block;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__left .nobiruno-howto__txt {
    top: 12%;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__right {
    width: 100%;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__right img {
    width: 100%;
    height: 300px;
  }

  .nobiruno .nobiruno-howto__top .nobiruno-howto__right .nobiruno-howto__txt {
    top: 62%;
  }
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__left {
  border-radius: 40px;
  width: 49%;
  position: relative;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__left img {
  width: 50%;
  margin-right: -50%;
  display: block;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right {
  width: 49%;
  padding: 0 20px 20px;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .nobiruno-howto__logo {
  width: 40%;
  margin-bottom: 20px;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .nobiruno-howto__price {
  margin-bottom: 10px;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .nobiruno-howto__price span:first-child {
  font-size: 3.6rem;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .nobiruno-howto__price span:nth-child(2) {
  font-size: 1.2rem;
}

.nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .btn {
  margin-bottom: 20px;
}

@media screen and (width <= 768px) {
  .nobiruno .nobiruno-howto__bottom {
    margin-bottom: 20px;
  }

  .nobiruno .nobiruno-howto__bottom .nobiruno-howto__left {
    width: 100%;
  }

  .nobiruno .nobiruno-howto__bottom .nobiruno-howto__left img {
    width: 100%;
    display: block;
    position: relative;
    top: 60%;
  }

  .nobiruno .nobiruno-howto__bottom .nobiruno-howto__right {
    width: 100%;
  }

  .nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .nobiruno-howto__logo {
    width: 80%;
  }

  .nobiruno .nobiruno-howto__bottom .nobiruno-howto__right .btn {
    margin-bottom: 20px;
  }
}

.sports__head {
  font-size: 4.8rem;
  font-weight: 700;
}

.sports__head span {
  font-style: italic;
}

@media screen and (width <= 768px) {
  .sports__head {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.sports__hero {
  text-align: center;
  padding: 20px 20px 40px;
}

@media screen and (width <= 768px) {
  .sports__hero {
    padding: 20px;
  }

  .sports__hero__img {
    max-width: 50%;
    margin: 0 auto 10px;
  }
}

.sports__img {
  max-width: 40%;
  margin: 0 auto;
}

.sports__leovista {
  padding: 80px 40px;
}

@media screen and (width <= 768px) {
  .sports__leovista {
    padding: 20px;
  }
}

.sports__left {
  text-align: center;
}

@media screen and (width <= 768px) {
  .sports__left {
    width: 100%;
    margin-bottom: 40px;
  }
}

.sports__left img {
  width: 50%;
}

.sports__right {
  width: 49%;
}

@media screen and (width <= 768px) {
  .sports__right {
    width: 100%;
  }
}

.sports__right img {
  width: 50%;
  margin: 0 auto 20px;
}

.sports__right p {
  margin-bottom: 20px;
}

.csvoice__hero {
  text-align: center;
  padding: 80px 20px;
}

.csvoice__img {
  max-width: 40%;
  margin: 0 auto;
}

@media screen and (width <= 768px) {
  .csvoice__hero {
    padding: 40px;
  }

  .csvoice__img {
    max-width: 100%;
  }
}

.page__head {
  font-size: 4.8rem;
  font-weight: 700;
}

@media screen and (width <= 768px) {
  .page__head {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}

.news {
  padding: 40px;
}

.news__item {
  margin-bottom: 20px;
}

.news__item span {
  display: block;
}

.news__img {
  width: 20%;
}

.news__img img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.news__txt {
  width: 80%;
  padding: 20px;
}

.news__title {
  font-weight: 700;
}

.news__des, .news__date {
  margin-bottom: 10px;
}

@media screen and (width <= 768px) {
  .news {
    padding: 20px;
  }

  .news__item {
    margin-bottom: 20px;
  }

  .news__item span {
    display: block;
  }

  .news__img {
    background-color: #f6f6f6;
    width: 100%;
  }

  .news__img img {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }

  .news__txt {
    width: 100%;
    padding: 20px 10px;
  }

  .news__des, .news__date {
    margin-bottom: 10px;
  }
}

.faq {
  padding: 40px;
}

.faq__head {
  text-align: center;
  margin-bottom: 40px;
}

.faq__item {
  text-align: center;
  width: 33%;
  margin-bottom: 40px;
}

.faq__item img {
  width: 30%;
}

.faq__item p {
  font-size: 1.2rem;
  font-weight: 700;
}

.faq__img {
  margin-bottom: 10px;
}

.faq__head2 {
  margin-bottom: 20px;
  padding: 20px;
}

.faq__list {
  margin-bottom: 20px;
}

.faq__q {
  color: #0071b8;
  background-color: #f6f6f6;
  margin-bottom: 10px;
  padding: 20px;
  font-weight: 700;
}

.faq__a {
  margin-bottom: 20px;
  padding: 10px 20px;
}

@media screen and (width <= 768px) {
  .faq {
    padding: 20px;
  }

  .faq__head {
    margin-bottom: 20px;
  }

  .faq__item {
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .faq__item a {
    align-items: center;
    display: flex;
  }

  .faq__item p {
    width: 70%;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .faq__img {
    text-align: center;
    width: 30%;
    margin-bottom: 0;
  }

  .faq__head2 {
    margin-bottom: 20px;
    padding: 20px;
  }

  .faq__list {
    margin-bottom: 20px;
  }

  .faq__q {
    color: #0071b8;
    background-color: #f6f6f6;
    margin-bottom: 10px;
    padding: 20px;
    font-weight: 700;
  }

  .faq__a {
    margin-bottom: 20px;
    padding: 10px 20px;
  }
}

/*# sourceMappingURL=main.css.map */
