// フォントサイズ
$fontsize-s: 1.2rem;
$fontsize-base: 1.4rem;
$fontsize-l: 1.6rem;
$fontsize-xl:1.8rem ;
$fontsize-xxl:2.4rem ;
$fontsize-xxxl:3.6rem ;
$fontsize-xxxxl:4.8rem ;

// フォントウェイト
$fontweight-lighter:200;
$fontweight-light: 400;
$fontweight-bold: 700;
$fontweight-bolder:800 ;

// ベースカラー
$base-color-400: #000;
$base-color-300: #3b3e3d;
$base-color-200:#F6F6F6;
$base-color-100: #FBFDFC;

// メインカラー
$main-color:#00B27A;

$main-color-dark:#BEA38E;
$main-color-light:#FFFCFA;

// サブカラー
$sub-color: #0071B8;

// アクセントカラー
$accent-color: #E91E79;