@use 'mixin' as *;
@use 'setting' as *;

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    text-decoration: none;
    color: $base-color-300;

    &:hover {

        transition: .8s;
    }
}

img {
    max-width: 100%;
    vertical-align: bottom;
}

html {
    font-size: 62.5%;
    height: 100%;
}

body {
    font-family: "Hiragino Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.7;
    font-size: $fontsize-base;
    color: $base-color-300;
    width: 100%;
    height: 100%;
    background-color: $base-color-100;
    letter-spacing: 1.5px;
}

input,
textarea,
button {
    color: $base-color-300;
    font-size: 16px;
    outline: 0;
    border: 1px solid $base-color-300;
}

a:focus {
    outline: 0;
}

.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.fs-xxxl {
    $fontsize-xxxl: 3.6rem;
}

.fs-xxl {
    $fontsize-xxl: 2.4rem;
}

.fs-xl {
    $fontsize-xl: 1.8rem;
}

.fs-l {
    $fontsize-l: 1.6rem;
}

.fs-m {
    $fontsize-base: 1.4rem;
}

.fs-s {
    $fontsize-s: 1.2rem;
}

.fs-xs {
    font-size: 10px;
}

@media screen and (max-width: 768px) {

    .xxx-large,
    .xx-large,
    .x-large {
        font-size: $fontsize-xl;
    }
}

.fw-lighter {
    font-weight: $fontweight-lighter;
}

.fw-light {
    font-weight: $fontweight-light;
}

.fw-bold {
    font-weight: $fontweight-bold;
}

.fw-bolder {
    font-weight: $fontweight-bolder;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-just {
    text-align: justify;
}

.only-sp {
    display: none !important;

    @include mq() {
        display: block !important;
    }
}

.only-pc {
    @include mq() {
        display: none !important;
    }
}

.pd10 {
    padding: 10px !important;

    @media screen and (max-width: 768px) {
        padding: 10px !important;
    }
}

.pd20 {
    padding: 20px !important;

    @media screen and (max-width: 768px) {
        padding: 10px !important;
    }
}

.pd30 {
    padding: 30px !important;

    @media screen and (max-width: 768px) {
        padding: 10px !important;
    }
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb05 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mb70 {
    margin-bottom: 70px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.mb90 {
    margin-bottom: 90px !important;
}

.mb100 {
    margin-bottom: 100px !important;
}

.mt0 {
    margin-top: 0px !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;

}

.container--m {
    max-width: 860px;
    margin: 0 auto;

}


.container--s {
    max-width: 600px;
    margin: 0 auto;

}

.container--xs {
    max-width: 320px;
    margin: 0 auto;

}

.bg-main {
    background-color: $main-color;
}

.bg-gray {
    background-color: $base-color-200;
}

.bg-dark-gray {
    background-color: $base-color-300;
}

.bg-white {
    background-color: $main-color-light;
}

.bg-navy {
    background-color: #012B51
}


.fc-base100 {
    color: $base-color-100 !important;
}

.fc-base200 {
    color: $base-color-200 !important;
}

.fc-base300 {
    color: $base-color-300 !important;
}

.fc-base400 {
    color: $base-color-400 !important;
}

.fc-main {
    color: $main-color !important;
}

.fc-accent {
    color: $accent-color;
}

.fc-pink-gradation {
    background: linear-gradient(90deg, rgba(250, 152, 116, 1) 0%, rgba(241, 125, 192, 1) 35%, rgba(121, 157, 237, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fc-red {
    color: #EC0000;
}

.fc-navy-gradation {
    background: linear-gradient(90deg, rgba(1,43,81,1) 0%, rgba(3,63,107,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}