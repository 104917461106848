@use 'mixin' as *;
@use 'setting' as *;

.flex--between {
    justify-content: space-between;
}

.flex--around {
    justify-content: space-around;
}

.flex--end {
    justify-content: flex-end;
}

.flex--center {
    justify-content: center;
}

.flex--aligncenter {
    align-items: center;
}

.flex--alignend {
    align-items: flex-end;
}

.flex--direction {
    flex-direction: column;
}

.flex--reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;

}

.flex {
    display: flex;
    flex-wrap: wrap;

    .flex__item--2 {
        width: calc(50% - 10px);
        margin: 5px;

    }

    .flex__item--3 {
        width: calc(33.3333% - 5px);
        margin: 2.5px;
    }

    .flex__item--4 {
        width: calc(25% - 5px);
        margin: 2.5px;
    }

    .flex__item--5 {
        width: calc(20% - 5px);
        margin: 2.5px;
    }

}

@include mq {
    .flex--sp100>div {
        width: calc(100% - 10px) !important;
        margin: 5px;

    }

    .flex--sp100>article {
        width: calc(100% - 10px) !important;
        margin: 5px;


    }

    .flex--sp100>li {
        width: calc(1000% - 10px) !important;
        margin: 5px;
    }

    .flex--sp50>div {
        width: calc(50% - 10px) !important;
        margin: 5px;

    }

    .flex--sp50>article {
        width: calc(50% - 10px) !important;
        margin: 5px;

    }

    .flex--sp50>li {
        width: calc(50% - 10px) !important;
        margin: 5px;
    }
}

.iframeWrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.iframeWrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;



    tr {
        border-bottom: 1px solid $main-color-dark;
    }

    th {
        width: 20%;
        vertical-align: top;

        @include mq {
            width: 35%;
        }
    }

    th,
    td {
        text-align: left;
        padding: 20px;

        @include mq {
            padding: 0px 15px;
            font-size: 12px;
            vertical-align: middle;
        }
    }

    td {
        width: 80%;
        padding: 20px;

    }


}


.table--width {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th:first-child {
        border-radius: 5px 0 0 0;
    }

    th:last-child {
        border-radius: 0 5px 0 0;
        border-right: 1px solid $base-color-300;
    }

    th {
        text-align: center;
        color: white;
        background: $base-color-300;
        border-left: 1px solid $base-color-300;
        border-top: 1px solid $base-color-300;
        border-bottom: 1px solid $base-color-300;
        width: 25%;
        padding: 10px 0;
    }

    td {
        text-align: center;
        border-left: 1px solid $base-color-300;
        border-bottom: 1px solid $base-color-300;
        border-top: none;
        width: 25%;
        padding: 10px 0;
    }

    td:last-child {
        border-right: 1px solid $base-color-300;
    }

    tr:last-child td:first-child {
        border-radius: 0 0 0 5px;
    }

    tr:last-child td:last-child {
        border-radius: 0 0 5px 0;
    }
}


.btn {
    img {
        width: 20px;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
        margin-right: 10px;
    }

    a,
    input {
        color: $main-color-light;
        background-color: $main-color;
        border: 2px solid $main-color;
        display: block;

        &:hover {

            background-color: $main-color-light;
            color: $main-color;
            border: 2px solid $main-color;
        }
    }
}

.btn--default {
    a {
        max-width: 240px;
        padding: 10px 0;
        text-align: center;
        display: block;
    }
}

.btn--reverse {

    a,
    input {
        color: $main-color;
        background-color: $main-color-light;
        border: 2px solid $main-color-light;

        &:hover {

            background-color: $main-color;
            color: $main-color-light;
            border: 2px solid $main-color-light;
        }
    }
}


.tab {
    &__list {}

    &__item {
        width: 25%;
        background-color: $base-color-300;
        border: 1px solid $base-color-100;

        @include mq {
            width: 50%;
        }

        a {
            color: $base-color-100;
            padding: 5px 20px;
            display: block;
            text-align: center;

        }
    }

    &__content {
        display: none;
        padding-top: 20px;
    }

    .is_active {
        background-color: $base-color-400;
    }

    .is_show {
        display: block;
    }

}

.tag {
    &__item {
        color: #bdbdbd;
        margin: 2.5px;

        &:before {
            content: "#";
        }
    }
}

.form {

    font-size: 14px;
    text-align: left;
    margin-left: 20%;

    @include mq {
        margin-left: 0%;
        padding: 20px;
    }

    &__head {
        color: $base-color-100;
    }

    &__label {}

    &__item {
        margin-bottom: 20px;



        &--txt {

            textarea {

                width: 100%;
                padding: 10px 0;
                text-indent: 1em;

            }
        }



        input,
        button,
        textarea {
            width: 100%;
            border: solid 0px $base-color-200;
            text-indent: 1em;
            background-color: $main-color-light;
            color: $base-color-400;


        }

        input {
            width: 100%;
            display: block;
            text-decoration: none;
            letter-spacing: 2px;
            padding: 12px 0;


            border: 0px;


        }

    }

    &__item--contact {
        input {

            background-color: $main-color-dark;
            color: $main-color-light;
            cursor: pointer;

            &:hover {

                opacity: .8;

            }
        }

    }
}

/*トグルボタンが押されたときに付与するクラス*/
.header-nav.active {
    transform: translateY(0%);
}

/*トグルボタンのスタイルを指定*/
.toggle {
    display: none;

    @include mq {
        display: block;
        position: fixed;
        /* bodyに対しての絶対位置指定 */
        right: 10px;
        top: 10px;
        width: 42px;
        height: 42px;
        cursor: pointer;
        z-index: 3;
    }

    span {
        border-bottom: solid 2px $base-color-400;
        display: block;
        position: absolute;
        -webkit-transition: .35s ease-in-out;
        /*変化の速度を指定*/
        -moz-transition: .35s ease-in-out;
        /*変化の速度を指定*/
        transition: .35s ease-in-out;
        /*変化の速度を指定*/
        left: 6px;
    }

    span:nth-child(1) {
        top: 7px;
        width: 35px;
    }

    span:nth-child(2) {
        top: 17px;
        width: 35px;
    }

    span:nth-child(3) {
        top: 27px;
        width: 35px;
    }
}

.toggle.active span {
    border-bottom: solid 2px $base-color-400;
    box-shadow: 0px 0px 0px $base-color-400;

    &:nth-child(1) {
        top: 18px;
        left: 6px;
        width: 35px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:nth-child(2) {
        top: 18px;
        width: 35px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);

    }

    &:nth-child(3) {
        top: 18px;
        width: 35px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);

    }
}

.card {
    margin-bottom: 20px;
    &__item {
        width:49%;
        padding: 20px;
        box-shadow: 1px 1px 1px $base-color-400;
        box-shadow: 0px 7px 20px -6px rgba(0, 0, 0, 0.3);
        p {
            font-size: $fontsize-xxl;
            font-weight: $fontweight-bold;
            padding: 20px 10px 20px 10px;
            &:nth-child(1) {
              border-bottom: 2px solid rgba($base-color-300,0.1);
            }
          
        }
       
    }
    @include mq {
        margin-bottom: 10px;
        &__item {
            width:100%;
            padding: 10px;
            p {
                font-size: $fontsize-xl;
                font-weight: $fontweight-bold;
                padding: 20px 0px 20px 0px;
                &:nth-child(1) {
                  border-bottom: 2px solid rgba($base-color-300,0.1);
                }
              
            }
           
        }
    }
}


.cs {
    padding: 40px;

    @include mq {
        padding: 20px;
    }

    &__head {
        text-align: center;
        margin-bottom: 10px;
    }

    &__btn {
        text-align: center;
        margin-bottom: 20px;
    }

    &__list {}

    &__item {
        position: relative;
        border-radius: 40px;
        margin-bottom: 20px;

        @include mq {
            display: block;
            width: 100%;
            margin-bottom: 20px;
        }
    }

    &__item--col2 {
        width: 49%;

        @include mq {
            width: 100%;
        }
    }

    &__item--col3 {
        width: 32%;

        @include mq {
            width: 100%;
        }
    }

    &__innr {
        padding: 40px 40px 0px 40px;

        @include mq {
            padding: 20px 20px 0px 20px;
        }

    }

    &__name {
        font-size: $fontsize-xxl;
        text-align: center;
        margin-bottom: 10px;
        @include mq {
            font-size: $fontsize-xl;
        }
    }

    &__label {
        color: $main-color;
        border: 1px $main-color solid;
        padding: 5px 10px;
        border-radius: 20px;
        margin: 0 auto 20px auto;
        display: block;
        max-width: 200px;
        text-align: center;
        font-size: $fontsize-s;
    }

    &__txt {
        margin-bottom: 20px;
    }
    img{
        width: 100%;
    }
    &__catch {
        font-size: $fontsize-xxxl;
        font-weight: $fontweight-bold;
        text-align: center;
        margin: 0 auto 20px auto;

        @include mq {
            font-size: $fontsize-xxl;
        }
    }
}