@use 'mixin' as *;
@use 'setting' as *;

.header {
    background-color: $base-color-100;
    font-size: $fontsize-l;
    font-weight: $fontweight-bold;
    padding: 40px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    &__top {
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            background-color: $base-color-100;
        }
    }

    &__logo {
        img {
            width: 217px;
            vertical-align: middle;
        }

        @media screen and (max-width: 768px) {
            position: absolute;
            z-index: 2;
            top: 0px;
            left: 10px;

            img {
                width: 200px;
            }
        }
    }

    &__list {
        @media screen and (max-width: 768px) {
            background-color: $base-color-300;
            position: fixed;
            z-index: 2;
            bottom: 0;
            left: 0;
            text-align: center;
            width: 100%;
            justify-content: space-around;
            padding: 10px 0;
        }
    }

    &__item {
        margin-left: 20px;

        img {
            width: 20px;
        }

        @media screen and (max-width: 768px) {
            margin-left: 10px;

            &:nth-child(3) img {
                filter: invert(100%);
            }

            &:nth-child(2) img {
                filter: invert(100%);
            }
        }
    }


    &__contact {}

    &__bottom {}
}

.header-contact {
    max-width: 200px;
    font-size: $fontsize-s;
    text-align: center;

    a {
        padding: 5px 10px;
    }

}

.header-nav {
    @media screen and (max-width: 768px) {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        transform: translateY(-100%);
        transition: all 0.6s;
        background: $base-color-100;
        margin-right: 0px;
        display: block;
        border-top: 4px solid $main-color;
        border-bottom: 4px solid $main-color;
    }

    &__list {
        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            padding: 20px 0 0 0;
        }
    }

    &__item {
        border-right: 1px rgba(000, 000, 000, 0.1) solid;
        padding: 0 20px;

        @media screen and (max-width: 768px) {
            border-right: 0px rgba(000, 000, 000, 0.1) solid;
            border-bottom: 1px rgba(000, 000, 000, 0.1) solid;
            margin: 10px 0;
            padding-bottom: 10px;
        }
    }

    &__item:last-child {
        border-right: 0px rgba(000, 000, 000, 0.1) solid;

        @media screen and (max-width: 768px) {
            margin: 0;
        }
    }


}


.hero {
    @media screen and (max-width: 768px) {
        display: block;
    }

    &__left {

        width: 40%;
        position: relative;

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
        }

    }

    &__wrap {
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
        /* Safari用 */
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) {
            padding: 20px;
            position: relative;
            top: 0%;
            left: 0%;
            margin-right: 0%;
            -webkit-transform: translate(-0%, -0%);
            /* Safari用 */
            transform: translate(-0%, -0%);
        }
    }

    &__catch {
        font-size: $fontsize-xxxl;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }

    &__head {
        margin-bottom: 10px;
    }

    &__txt {
        margin-bottom: 20px;
    }

    &__right {
        width: 60%;

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }

    &__btn {
        @media screen and (max-width: 768px) {
            a {
                margin: 0 auto;
            }
        }
    }
}

.top-menu {
    padding: 40px;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    a:hover &__btn {
        letter-spacing: 3px;
        transition: 1.0s;
    }

    &__list {}

    &__item {
        width: 33%;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__txt {
        text-align: center;
        position: absolute;
        top: 20%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
        /* Safari用 */
        transform: translate(-50%, -50%);
    }

    &__head {
        font-size: $fontsize-xxl;
        margin-bottom: 10px;
    }

    &__btn {}
}

.top-news {
    margin: 0 40px;
    padding: 40px;

    @media screen and (max-width: 768px) {
        margin: 0 20px;
        padding: 20px;
    }

    &__head {
        margin-bottom: 10px;
    }

    &__btn {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px rgba(000, 000, 000, 0.1) solid;
    }

    &__list {}

    &__item {
        margin-bottom: 10px;
    }

    &__date {
        margin-right: 10px;
    }

}

.top-manga {
    padding: 40px 40px 0px 40px;
    margin: 0 40px;

    @media screen and (max-width: 768px) {
        margin: 0 20px;
        padding: 20px;
    }


    &__left {
        width: 50%;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

    }

    &__right {
        width: 50%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__innr {
        text-align: center;
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
        /* Safari用 */
        transform: translate(-50%, -50%);

        @media screen and (max-width: 768px) {
            width: 55%;
            position: relative;
            top: 0%;
            left: 0%;
            margin-right: 0%;
            -webkit-transform: translate(-0%, -0%);
            /* Safari用 */
            transform: translate(-0%, -0%);

        }
    }

    &__head {
        max-width: 400px;

        @media screen and (max-width: 768px) {
            width: 180%;
            margin: 0 auto 10px auto;
            text-align: center;
            display: block;
        }
    }

    &__btn {
        @media screen and (max-width: 768px) {
            width: 180%;
            margin: 0 auto 10px auto;
            text-align: center;
        }
    }

    &__img {
        max-width: 400px;
    }
}

.footer {
    padding: 40px;

    @media screen and (max-width: 768px) {
        padding: 20px 20px 80px 20px;

    }

    &__top {
        margin-bottom: 20px;
    }

    &__bottom {}

    &__item {
        margin-left: 20px;


        img {
            width: 20px;
        }
    }

    &__item:nth-child(1) {
        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0 auto 20px auto;
            text-align: center;
        }

    }
}

.footer-logo {
    img {
        width: 217px;
    }
}

.footer-nav {
    &__list {}

    &__item {
        padding: 0 20px;

        @media screen and (max-width: 768px) {
            padding: 0 10px;
            text-align: left;
            font-size: $fontsize-s;
        }
    }
}

.about {
    text-align: center;
    padding-bottom: 40px;


    &__wrap {}

    &__innr {
        padding: 0 20px 20px 20px;

    }

    &__head {
        font-size: $fontsize-xxxxl;
        font-weight: $fontweight-bold;

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }

    &__img {}

    &__txt {
        color: $base-color-300;
    }

    &__copy {
        font-weight: $fontweight-bold;
        font-size: 48px;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }


    }
}

.product {
    padding-bottom: 40px;


    &__wrap {}

    &__head {
        font-size: $fontsize-xxxxl;
        font-weight: $fontweight-bold;

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }


    &__price {
        margin-bottom: 10px;

        span:nth-child(1) {
            font-size: $fontsize-xxxxl;
        }

        span:nth-child(2) {
            font-size: $fontsize-s;
        }
    }

    &__txt {
        margin-bottom: 20px;

    }

    &__top {
        padding: 40px;

        @media screen and (max-width: 768px) {
            padding: 80px 20px 0 20px;
        }

        .product__innr {

            width: 60%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .product__logo {
            max-width: 300px;
            margin-bottom: 10px;
        }


        .product__img {
            width: 40%;
            padding: 80px 0px 0px 0px;

            img {
                width: 80%;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 20px 0px 0px 0px;
                margin: 0 auto;

                img {
                    width: 100%;
                }
            }
        }



        .product__price {
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }

        .btn a {
            margin: 0 0 40px 0;

            @media screen and (max-width: 768px) {
                margin: 0 auto 20px auto;
                text-align: center;
            }
        }


    }


    &__bottom {
        padding: 40px;

        @media screen and (max-width: 768px) {
            padding: 0px;
        }

        >div {
            padding: 80px 20px 0 20px;
            width: 50%;

            @media screen and (max-width: 768px) {

                width: 100%;
            }

        }

        .product__logo {
            max-width: 240px;
            margin: 0 auto 40px auto;
        }

        .product__head {
            text-align: center;
            line-height: 1.2;
            margin-bottom: 40px;
        }

        .product__txt {
            padding: 0 20px;

            @media screen and (max-width: 768px) {

                padding: 0;
            }
        }

        .product__price {
            text-align: center;
        }

        .btn a {
            margin: 0 auto 40px auto;
            text-align: center;
        }

        .product__img {
            padding: 0;
            width: 60%;
            margin: 0 auto;
            text-align: center;
        }

    }

}


.nobiruno {
    &__head {
        font-size: $fontsize-xxxxl;
        font-weight: $fontweight-bold;

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }

    &__challenge {
        padding: 40px;

        @media screen and (max-width: 768px) {
            padding: 20px;
        }
    }

    .nobiruno-hero {
        text-align: center;
        padding: 40px;

        &__img-package {
            max-width: 25%;
            margin: 0 auto 20px auto;
        }

        &__logo {
            max-width: 10%;
            margin: 0 auto 20px auto;
        }

        &__img-shadow {
            max-width: 25%;
            margin: 0 auto 20px auto;
        }

        @media screen and (max-width: 768px) {

            padding: 20px;

            &__img-package {
                max-width: 50%;
                margin: 0 auto 10px auto;
            }

            &__logo {
                max-width: 50%;
                margin: 0 auto 10px auto;
            }

            &__img-shadow {
                max-width: 50%;
                margin: 0 auto 10px auto;
            }

            &__txt {
                text-align: left;
            }
        }
    }

    .nobiruno-about {
        padding: 40px 0 40px 40px;

        &__left {
            width: 50%;
        }

        &__right {
            width: 50%;
        }

        @media screen and (max-width: 768px) {
            padding: 20px;

            &__left {
                width: 100%;
            }

            &__right {
                width: 100%;
            }

            &__txt {}

        }
    }

    .nobiruno-detail {
        text-align: center;
        padding: 40px;

        &__img-arginine {
            max-width: 50%;
            margin: 0 auto 20px auto;
        }

        &__txt {

            margin: 0 auto 20px auto;
        }

        &__img-detail {
            margin: 0 auto 80px auto;
        }

        @media screen and (max-width: 768px) {
            padding: 20px;

            &__img-arginine {
                max-width: 100%;
                margin: 0 auto 10px auto;
            }

            &__txt {
                text-align: left;
                margin: 0 auto 20px auto;
                padding: 0 10px;
            }

            &__img-detail {
                margin: 0 auto 20px auto;
            }

        }

    }

    .nobiruno-howto {
        padding: 40px;

        .nobiruno__head {
            text-align: center;
            margin-bottom: 20px;
        }

        @media screen and (max-width: 768px) {
            padding: 20px;
        }

        &__top {
            text-align: center;
            margin-bottom: 40px;

            .nobiruno-howto__txt {
                font-size: $fontsize-xxl;
                font-weight: $fontweight-bold;
                position: absolute;

                margin-right: -50%;
                -webkit-transform: translate(-50%, -50%);
                /* Safari用 */
                transform: translate(-50%, -50%);
            }

            .nobiruno-howto__left {
                width: 49%;
                position: relative;

                div {
                    height: 100px;
                    display: block;
                }

                img {
                    height: 500px;
                }

                .nobiruno-howto__txt {

                    top: 15%;
                    left: 50%;

                }
            }

            .nobiruno-howto__right {
                width: 49%;
                position: relative;

                div {
                    height: 100px;
                    display: block;
                }

                img {
                    height: 500px;
                    width: 100%;
                }

                .nobiruno-howto__txt {
                    color: $base-color-100;
                    filter: brightness(130%);
                    top: 58%;
                    left: 50%;

                }
            }

            @media screen and (max-width: 768px) {

                margin-bottom: 20px;

                .nobiruno-howto__txt {
                    font-size: $fontsize-xl;
                    top: 50%;
                }

                .nobiruno-howto__left {
                    width: 100%;
                    margin-bottom: 20px;

                    div {
                        height: 0px;
                        display: block;
                    }

                    img {
                       
                    }

                    .nobiruno-howto__txt {
                        top: 12%;
                    }
                }

                .nobiruno-howto__right {
                    width: 100%;

                    img {
                        height: 300px;
                        width: 100%;
                    }

                    .nobiruno-howto__txt {
                        top: 62%;
                    }
                }
            }
        }

        &__bottom {
            .nobiruno-howto__left {
                position: relative;
                width: 49%;
                border-radius: 40px;

                img {
                    display: block;
                    width: 50%;
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    margin-right: -50%;
                    -webkit-transform: translate(-50%, -50%);
                    /* Safari用 */
                    transform: translate(-50%, -50%);
                }
            }

            .nobiruno-howto__right {
                width: 49%;
                padding: 0 20px 20px 20px;

                .nobiruno-howto__logo {
                    width: 40%;
                    margin-bottom: 20px;
                }

                .nobiruno-howto__price {
                    margin-bottom: 10px;

                    span:nth-child(1) {
                        font-size: $fontsize-xxxl;
                    }

                    span:nth-child(2) {
                        font-size: $fontsize-s;
                    }


                }

                .btn {
                    margin-bottom: 20px;
                }
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 20px;

                .nobiruno-howto__left {
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                        position: relative;
                        top: 60%;

                    }
                }

                .nobiruno-howto__right {
                    width: 100%;

                    .nobiruno-howto__logo {
                        width: 80%;
                    }

                    .btn {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.sports {
    &__head {
        font-size: $fontsize-xxxxl;
        font-weight: $fontweight-bold;

        span {
            font-style: italic;
        }

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }

    &__hero {
        text-align: center;
        padding: 20px 20px 40px 20px;

        @media screen and (max-width: 768px) {

            padding: 20px;

            &__img {
                max-width: 50%;
                margin: 0 auto 10px auto;
            }
        }
    }

    &__img {
        max-width: 40%;
        margin: 0 auto;

    }

    &__leovista {
        padding: 80px 40px;

        @media screen and (max-width: 768px) {
            padding: 20px;
        }
    }

    &__left {
       
        text-align: center;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 40px;
        }

        img {
            width: 50%;
        }
    }

    &__right {
        width: 49%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }

        img {
            width: 50%;
            margin: 0 auto 20px auto;
        }

        p {
            margin-bottom: 20px;
        }
    }

}


.csvoice {


    &__hero {
        text-align: center;
        padding: 80px 20px;


    }

    &__img {
        max-width: 40%;
        margin: 0 auto;

    }

    @media screen and (max-width: 768px) {
        &__hero {
            padding: 40px;
        }

        &__img {
            max-width: 100%;
        }


    }
}

.page {
       &__head {
        font-size: $fontsize-xxxxl;
        font-weight: $fontweight-bold;

        @media screen and (max-width: 768px) {
            font-size: $fontsize-xxl;
            margin-bottom: 10px;
        }
    }
}

.news {
    padding: 40px;

    &__list {}

    &__item {
        span {
            display: block;
        }

        margin-bottom: 20px;
    }

    &__img {
        width: 20%;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }

    &__txt {
        width: 80%;
        padding: 20px;
    }

    &__title {
        font-weight: $fontweight-bold;

    }

    &__des {
        margin-bottom: 10px;
    }

    &__date {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;

        &__list {}

        &__item {
            span {
                display: block;
            }

            margin-bottom: 20px;
        }

        &__img {
            width: 100%;
            background-color: $base-color-200;

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }

        &__txt {
            width: 100%;
            padding: 20px 10px;
        }


        &__des {
            margin-bottom: 10px;
        }

        &__date {
            margin-bottom: 10px;
        }
    }

}

.faq {
    padding: 40px;

    &__head {
        margin-bottom: 40px;
        text-align: center;
    }

    &__menu {}

    &__item {
        width: 33%;
        text-align: center;
        margin-bottom: 40px;

        img {
            width: 30%;
        }

        p {
            font-size: $fontsize-s;
            font-weight: $fontweight-bold;
        }
    }

    &__img {
        margin-bottom: 10px;
    }

    &__head2 {
        padding: 20px;
        margin-bottom: 20px;
    }

    &__list {
        margin-bottom: 20px;
    }

    &__q {
        padding: 20px;
        margin-bottom: 10px;
        color: $sub-color;
        font-weight: $fontweight-bold;
        background-color: $base-color-200;
    }

    &__a {
        padding: 10px 20px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;

        &__head {
            margin-bottom: 20px;
         
        }

        &__menu {}

        &__item {
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
           
            a{
                display: flex;
                align-items: center;
             
            }

            img {
                
            }

            p {
                width: 70%;
                font-size: $fontsize-s;
                font-weight: $fontweight-bold;
                
            }
        }

        &__img {
            width: 30%;
            margin-bottom: 0px;
            text-align: center;

        }

        &__head2 {
            padding: 20px;
            margin-bottom: 20px;
        }

        &__list {
            margin-bottom: 20px;
        }

        &__q {
            padding: 20px;
            margin-bottom: 10px;
            color: $sub-color;
            font-weight: $fontweight-bold;
            background-color: $base-color-200;
        }

        &__a {
            padding: 10px 20px;
            margin-bottom: 20px;
        }
    }
}